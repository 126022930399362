import React from "react"

import SEO from "../components/atoms/seo"
import VerticalLayout from "../layouts/vertical-layout"
import Willow from "../components/atoms/willow"

import "../global.scss"

export default function Index() {
  return (
    <VerticalLayout className='home' scene={() => <Willow />}>
      <SEO title='Home' image='willow.webp' />
      <h1>
        <span>We</span> <span>Are</span> <span>Willow</span>
      </h1>
      <hr />
      <p className='subtitle' style={{ filter: "contrast(400%) grayscale(100%)" }}>
        We branch out to create engaging experiences. 🌳
      </p>
      <hr />
      <p>
        We are partners in business, and good friends who enjoy making games, and digital
        experiences.
      </p>
      <p>
        We love dance and electronic music, we are huge design nerds, and we love talking about what
        ifs, and nonconventional topics. We are inspired by others, and their cool projects, and new
        possibilities in up-and-coming technologies.
      </p>
      <p>
        Be it games, the web, VR/AR, the music we make, the stories we craft, or the visuals we
        attempt to perfect, we are powered by freedom of thought as we stand against the monotony of
        the world. We strive to tell our story whatever that may be, to be strikingly extravagant
        and unorthodox. To allow creativity to be expressed, without limitations, to inspire others,
        and to create a space of safety and imagination.
      </p>
    </VerticalLayout>
  )
}
