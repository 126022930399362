import * as THREE from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass"
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass"
import { BokehPass } from "three/examples/jsm/postprocessing/BokehPass"
//import { GUI } from "three/examples/jsm/libs/dat.gui.module"
import { GPUComputationRenderer } from "three/examples/jsm/misc/GPUComputationRenderer"

import { FirefliesShader, BoidsPosition, BoidsVelocity } from "../../shaders/Fireflies"

import THREECanvas from "./three-canvas"

const WIDTH = 32
const BOUNDS = 800
const BOUNDS_HALF = BOUNDS / 2

const firefliesController = {
  separation: 50.0,
  alignment: 4.0,
  cohesion: 100.0,
  freedom: 0.75,
}

const bokehController = {
  focus: 170.0,
  aperture: 8,
  maxblur: 0.0004,
}

export default class Willow extends THREECanvas {
  start() {
    super.start()

    console.log(this.motion)

    // Compute Shader
    this.initComputeShader()

    // Fireflies
    this.addFireflies()

    // Lights
    this.addLights()

    this.loadAndAddTree()
  }

  initPostprocessing() {
    super.initPostprocessing()

    const afterImagePass = new AfterimagePass(0.6)
    const bloomPass = new UnrealBloomPass(this.resolution, 2, 0, 0)
    const filmPass = new FilmPass(0, 0, 0, true)
    const bokehPass = new BokehPass(this.scene, this.camera, {
      focus: bokehController.focus,
      aperture: bokehController.aperture,
      maxblur: bokehController.maxblur,

      width: this.width,
      height: this.height,
    })

    this.composer.addPass(filmPass)
    this.composer.addPass(afterImagePass)
    this.composer.addPass(bloomPass)
    this.composer.addPass(bokehPass)

    this.postprocessing = {}
    this.postprocessing.bokeh = bokehPass
  }

  validate() {
    this.postprocessing.bokeh.uniforms["focus"].value = bokehController.focus
    this.postprocessing.bokeh.uniforms["aperture"].value = bokehController.aperture * 0.00001
    this.postprocessing.bokeh.uniforms["maxblur"].value = bokehController.maxblur
  }

  loadAndAddTree() {
    const loader = new GLTFLoader()

    loader
      .loadAsync(this.context.isMobile ? "WillowTreeMobile.glb" : "WillowTreeDesktop.glb", xhr => {
        this.props.onProgress && this.props.onProgress(loadingProgress)
      })
      .then(gltf => {
        const group = new THREE.Group()
        group.name = "WillowTree"

        const trunk = gltf.scene.children[0]
        if (trunk.material) {
          //Set Tree To Wireframe
          const wireBasic = new THREE.MeshBasicMaterial({
            color: 0x666666,
            wireframe: true,
            transparent: true,
            opacity: 0.1,
            fog: false,
          })
          trunk.material = wireBasic
        }
        group.add(trunk)

        const NUM_OF_LEAVES = gltf.scene.children.length - 1
        const leaf0 = gltf.scene.children[1]
        const leafMat = leaf0.material
        leafMat.depthWrite = true
        leafMat.alphaTest = 0.7
        leafMat.transparent = true
        leafMat.opacity = 0.8

        const leavesMesh = new THREE.InstancedMesh(leaf0.geometry, leafMat, NUM_OF_LEAVES)
        for (let i = 0; i < NUM_OF_LEAVES; i++) {
          const leaf = gltf.scene.children[i + 1]
          leaf.updateMatrix()
          leavesMesh.setMatrixAt(i, leaf.matrix)
        }
        group.add(leavesMesh)

        this.scene.add(group)

        const el = this.scene.getObjectByName("WillowTree")
        el.position.set(0, -200, 0)
        el.scale.set(15, 15, 15)
        this.model = el
      })
      .catch(err => console.log("An error happened trying to load the model:" + err))
  }

  addNebula() {
    const plane = new THREE.PlaneGeometry(1000, 1000)
    //const planeMesh = new THREE.Mesh(plane, )
  }

  addFireflies() {
    this.NUM_FIREFLIES = this.context.isMobile ? 400 : 1000

    const positions = new Float32Array(this.NUM_FIREFLIES * 3)
    const references = new Float32Array(this.NUM_FIREFLIES * 2)
    const alphas = new Float32Array(this.NUM_FIREFLIES)

    const vertex = new THREE.Vector3()
    const reference = new THREE.Vector2()

    for (let i = 0; i < this.NUM_FIREFLIES; i++) {
      vertex.x = (Math.random() - 0.5) * 50
      vertex.y = (Math.random() - 0.5) * 50
      vertex.z = 0
      vertex.toArray(positions, i * 3)

      reference.x = (i % WIDTH) / WIDTH
      reference.y = ~~(i / WIDTH) / WIDTH
      reference.toArray(references, i * 2)

      alphas[i] = 1
    }

    const fireflyGeometry = new THREE.BufferGeometry()
    fireflyGeometry.setAttribute("position", new THREE.Float32BufferAttribute(positions, 3))
    fireflyGeometry.setAttribute("reference", new THREE.Float32BufferAttribute(references, 2))
    fireflyGeometry.setAttribute("alpha", new THREE.Float32BufferAttribute(alphas, 1))

    this.fireflyUniforms = {
      size: { value: this.context.isMobile ? 5 : 1 },
      color: { value: new THREE.Color(0xffffff) },
      texturePosition: { value: null },
      textureVelocity: { value: null },
      time: { value: 1.0 },
      delta: { value: 0.0 },
    }

    const fireflyMat = new THREE.ShaderMaterial({
      uniforms: this.fireflyUniforms,
      vertexShader: FirefliesShader.vertex,
      fragmentShader: FirefliesShader.fragment,
      blending: THREE.AdditiveBlending,
      depthTest: false,
      transparent: true,
    })

    this.fireflies = new THREE.Points(fireflyGeometry, fireflyMat)
    this.scene.add(this.fireflies)
  }

  addLights() {
    const ambientLight = new THREE.AmbientLight(0x101010)
    this.scene.add(ambientLight)

    const hemisphereLight = new THREE.HemisphereLight(0x101010, 0x000000, 0.3)
    this.scene.add(hemisphereLight)
  }

  initComputeShader() {
    this.gpuCompute = new GPUComputationRenderer(WIDTH, WIDTH, this.renderer)

    if (this.context.isSafari) {
      this.gpuCompute.setDataType(THREE.HalfFloatType)
    }

    const dtPos = this.gpuCompute.createTexture()
    const dtVel = this.gpuCompute.createTexture()

    this.fillPositionTexture(dtPos)
    this.fillVelocityTexture(dtVel)

    this.velocityVariable = this.gpuCompute.addVariable(
      "textureVelocity",
      BoidsVelocity.fragment,
      dtVel,
    )
    this.positionVariable = this.gpuCompute.addVariable(
      "texturePosition",
      BoidsPosition.fragment,
      dtPos,
    )

    this.gpuCompute.setVariableDependencies(this.velocityVariable, [
      this.positionVariable,
      this.velocityVariable,
    ])
    this.gpuCompute.setVariableDependencies(this.positionVariable, [
      this.positionVariable,
      this.velocityVariable,
    ])

    this.positionUniforms = this.positionVariable.material.uniforms
    this.velocityUniforms = this.velocityVariable.material.uniforms

    this.positionUniforms["time"] = { value: 0.0 }
    this.positionUniforms["delta"] = { value: 0.0 }

    this.velocityUniforms["time"] = { value: 1.0 }
    this.velocityUniforms["delta"] = { value: 0.0 }
    this.velocityUniforms["testing"] = { value: 1.0 }
    this.velocityUniforms["separationDistance"] = { value: firefliesController.separation }
    this.velocityUniforms["alignmentDistance"] = { value: firefliesController.alignment }
    this.velocityUniforms["cohesionDistance"] = { value: firefliesController.cohesion }
    this.velocityUniforms["freedomFactor"] = { value: firefliesController.freedom }
    this.velocityUniforms["predator"] = { value: new THREE.Vector3() }
    this.velocityVariable.material.defines.BOUNDS = BOUNDS.toFixed(2)

    this.velocityVariable.wrapS = THREE.RepeatWrapping
    this.velocityVariable.wrapT = THREE.RepeatWrapping
    this.positionVariable.wrapS = THREE.RepeatWrapping
    this.positionVariable.wrapT = THREE.RepeatWrapping

    const error = this.gpuCompute.init()
    if (error !== null) console.error(error)
  }

  fillPositionTexture = texture => {
    const array = texture.image.data

    for (let i = array.length - 1; i >= 0; i -= 4) {
      const x = Math.random() * BOUNDS - BOUNDS_HALF
      const y = Math.random() * BOUNDS - BOUNDS_HALF
      const z = Math.random() * BOUNDS - BOUNDS_HALF

      array[i - 3] = x
      array[i - 2] = y
      array[i - 1] = z
      array[i] = 1
    }
  }

  fillVelocityTexture = texture => {
    const array = texture.image.data

    for (let i = array.length - 1; i >= 0; i -= 4) {
      const x = Math.random() - 0.5
      const y = Math.random() - 0.5
      const z = Math.random() - 0.5

      array[i - 3] = x * 10
      array[i - 2] = y * 10
      array[i - 1] = z * 10
      array[i] = 1
    }
  }

  update(time, delta) {
    if (this.model) {
      this.model.rotation.y += 0.001
      const motionSq = this.motion.lengthSql
      if (motionSq > 0) this.model.rotation.y += Math.sqrt(motionSq) * 0.01
    }

    this.positionUniforms["time"].value = time
    this.positionUniforms["delta"].value = delta
    this.velocityUniforms["time"].value = time
    this.velocityUniforms["delta"].value = delta
    this.velocityUniforms["predator"].value.set(
      (0.5 * this.mouse.x) / (this.width / 2),
      (-0.5 * this.mouse.y) / (this.height / 2),
      0,
    )

    this.gpuCompute.compute()

    this.fireflyUniforms["texturePosition"].value = this.gpuCompute.getCurrentRenderTarget(
      this.positionVariable,
    ).texture
    this.fireflyUniforms["textureVelocity"].value = this.gpuCompute.getCurrentRenderTarget(
      this.velocityVariable,
    ).texture

    const alphas = this.fireflies.geometry.attributes.alpha.array

    const emergence = 4

    for (let i = 0; i < this.NUM_FIREFLIES; i++) {
      alphas[i] = Math.pow(
        Math.max(0, Math.abs(Math.sin(time * 0.001 + (i % emergence))) * 2.0 - 1.0),
        10.0,
      )
    }

    this.fireflies.geometry.attributes.alpha.needsUpdate = true

    this.camera.position.x += (this.mouse.x * 0.1 + this.orientation.x * 0.5 - this.camera.position.x) * 0.05
    this.camera.position.y += (-this.mouse.y * 0.1 + this.orientation.y * 0.5 - this.camera.position.y) * 0.05
    this.camera.lookAt(this.scene.position)

    this.composer.render(delta)

    this.requestID = window.requestAnimationFrame(this.animate)
  }
}
